import React from 'react'
import { Helmet } from 'react-helmet'

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="/fonts/Rawson/Latinotype-Rawson-Alt-SemiBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Helmet>
      {element}
    </>
  )
}
