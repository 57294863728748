exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bli-medlem-tsx": () => import("./../../../src/pages/bli-medlem.tsx" /* webpackChunkName: "component---src-pages-bli-medlem-tsx" */),
  "component---src-pages-boka-samtal-tsx": () => import("./../../../src/pages/boka-samtal.tsx" /* webpackChunkName: "component---src-pages-boka-samtal-tsx" */),
  "component---src-pages-nyhetsbrev-tsx": () => import("./../../../src/pages/nyhetsbrev.tsx" /* webpackChunkName: "component---src-pages-nyhetsbrev-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-contract-tsx": () => import("./../../../src/templates/contract.tsx" /* webpackChunkName: "component---src-templates-contract-tsx" */),
  "component---src-templates-employee-tsx": () => import("./../../../src/templates/Employee.tsx" /* webpackChunkName: "component---src-templates-employee-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

